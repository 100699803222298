// helpers
import {
  checkErrorStatusForAllDays,
  checkErrorValidationForAllDays,
  offersStep
} from "../../../pages/Offers/offersHelpers";
import { isEqualOrGreater, checkForEmptyField, checkForValidEmail } from "@gl-shared/shared/helpers";
import { checkIsFieldFilled } from "../../utils/panel-helpers/helpers";

export const errorFieldActionTypes = {
  TOGGLE_ERROR_FIELD: "TOGGLE_ERROR_FIELD",
  RESET_ERROR_FIELD: "RESET_ERROR_FIELD"
};

export const initialState = {
  errorExists: false,
  errorField: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case errorFieldActionTypes.TOGGLE_ERROR_FIELD:
      return {
        ...state,
        errorExists: action.errorExists,
        errorField: action.errorField
      };
    case errorFieldActionTypes.RESET_ERROR_FIELD:
      return {
        ...state,
        errorExists: false,
        errorField: null
      };
    default:
      return state;
  }
};
export const errorFieldActionCreators = {
  // handling error fields before validation of switching steps for Hotel Offer Management
  errorFieldCheckInStepAction: (step, data) => (dispatch, getState) => {
    let errorCheck = true;
    switch (step) {
      case offersStep.offerDetails:
        let offerDetailsErrorField = {
          offer_name: false,
          event_categories: false,
          short_description: false,
          long_description: false
        };

        if (checkForEmptyField(data.name)) {
          offerDetailsErrorField["offer_name"] = true;
          errorCheck = false;
        }
        if (checkForEmptyField(data.short_description)) {
          offerDetailsErrorField.short_description = true;
          errorCheck = false;
        }
        if (checkForEmptyField(data.long_description)) {
          offerDetailsErrorField.long_description = true;
          errorCheck = false;
        }
        if (data.event_categories.length === 0) {
          offerDetailsErrorField.event_categories = true;
          errorCheck = false;
        }
        // Links check LABEL
        const linksLabelValidation = checkIsFieldFilled(data?.links, "label");
        if (linksLabelValidation?.filled === false) {
          for (let i = 0; i < linksLabelValidation?.missingFields?.length; i++) {
            offerDetailsErrorField[`link_label_${linksLabelValidation?.missingFields[i]}`] = true;
          }
          errorCheck = false;
        }
        // Links check URL
        const linksUrlValidation = checkIsFieldFilled(data?.links, "url");
        if (linksUrlValidation?.filled === false) {
          for (let i = 0; i < linksUrlValidation?.missingFields?.length; i++) {
            offerDetailsErrorField[`link_url_${linksUrlValidation?.missingFields[i]}`] = true;
          }
          errorCheck = false;
        }

        dispatch({
          type: errorFieldActionTypes.TOGGLE_ERROR_FIELD,
          errorExists: !errorCheck,
          errorField: offerDetailsErrorField
        });
        return errorCheck;

      case offersStep.pricing:
        let pricingErrorField = {
          vat: false,
          base_price: false,
          allow_children: false,
          child_price: false,
          infant_price: false,
          additional_unit_price: false,
          price_type: false
        };

        if (checkForEmptyField(data.vat)) {
          pricingErrorField.vat = true;
          errorCheck = false;
        }
        if (checkForEmptyField(data?.hotel_pricing?.base_price)) {
          pricingErrorField.base_price = true;
          errorCheck = false;
        }
        if (checkForEmptyField(data?.hotel_pricing?.additional_unit_price)) {
          pricingErrorField.additional_unit_price = true;
          errorCheck = false;
        }
        if (data?.hotel_pricing?.allow_children && checkForEmptyField(data?.hotel_pricing?.child_price)) {
          pricingErrorField.child_price = true;
          errorCheck = false;
        }
        if (
          data?.hotel_pricing?.allow_children &&
          data?.hotel_pricing?.allow_infants &&
          checkForEmptyField(data?.hotel_pricing?.infant_price)
        ) {
          pricingErrorField.infant_price = true;
          errorCheck = false;
        }
        if (checkForEmptyField(data.price_type.value)) {
          pricingErrorField.price_type = true;
          errorCheck = false;
        }

        dispatch({
          type: errorFieldActionTypes.TOGGLE_ERROR_FIELD,
          errorExists: !errorCheck,
          errorField: pricingErrorField
        });

        return errorCheck;

      case offersStep.availability:
        let availabilityErrorField = {
          minimum_units: false,
          maximum_units: false
        };

        if (!isEqualOrGreater(data.minimum_units?.value, data.maximum_units?.value)) {
          availabilityErrorField.maximum_units = true;
          availabilityErrorField.minimum_units = true;
          errorCheck = false;
        }
        if (getState().offers.availabilityErrorMsg) {
          if (checkErrorStatusForAllDays(getState().offers.availabilityErrorMsg)) {
            errorCheck = false;
          }
        }

        if (checkErrorValidationForAllDays(getState().offers.availabilityErrorIntervals)) {
          errorCheck = false;
        }

        dispatch({
          type: errorFieldActionTypes.TOGGLE_ERROR_FIELD,
          errorExists: !errorCheck,
          errorField: availabilityErrorField
        });

        return errorCheck;

      case offersStep.photos:
        return errorCheck;

      case offersStep.fulfilment:
        let fulfilmentErrorField = {
          booking_confirmation_copy_recipients: false,
          bookingRequestTo: false
        };

        if (
          !checkForEmptyField(data.booking_confirmation_copy_recipients) &&
          checkForValidEmail(data.booking_confirmation_copy_recipients)
        ) {
          fulfilmentErrorField.booking_confirmation_copy_recipients = true;
          errorCheck = false;
        }
        if (
          (!data.directly_bookable && checkForEmptyField(data.bookingRequestTo)) ||
          (!data.directly_bookable && checkForValidEmail(data.bookingRequestTo))
        ) {
          fulfilmentErrorField.bookingRequestTo = true;
          errorCheck = false;
        }
        dispatch({
          type: errorFieldActionTypes.TOGGLE_ERROR_FIELD,
          errorExists: !errorCheck,
          errorField: fulfilmentErrorField
        });
        return errorCheck;

      case offersStep.summary:
        return errorCheck;
      default:
        return true;
    }
  },
  resetErrorFieldAction: () => dispatch => {
    dispatch({
      type: errorFieldActionTypes.RESET_ERROR_FIELD
    });
  }
};
