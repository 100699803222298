import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// @ts-ignore
import DateRangePicker from "react-bootstrap-daterangepicker";
// helpers
import {
  providerRootUrl,
  pagesUrl,
  removeTrailingSlash,
  shouldShowRefreshPageIcon
} from "../../common/utils/panel-helpers/helpers";
// components
// @ts-ignore
import { CustomTooltip } from "@gl-shared/shared/ui";
// icons
// @ts-ignore
import { getLocalLogoIcon, calendarSolidIcon, refreshIcon } from "@gl-shared/shared/vector";
// actions
import { useActions } from "../../common/hooks/useActions";
import { homeActionCreators } from "../../common/store/modules/homeModule";
import { bookingsActionCreators } from "../../common/store/modules/bookingsModule";
import { dashboardActionCreators } from "../../common/store/modules/dashboardModule";
import { offersActionCreators } from "../../common/store/modules/offersModule";

const ranges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
  // Removed for now
  // "Year-to-date": [moment().startOf("year"), moment()]
};

interface AppHeaderTypes {
  loadLogins: any;
  loadSettings: (hotelId: any) => void;
  handleSectionLoader: (loadingState: any, sectionName: any) => void;
  loadDashboardAndThenBookings: () => void;
  loadBookingsAndThenDashboard: () => void;
}

const AppHeader = ({
  loadLogins,
  loadSettings,
  handleSectionLoader,
  loadDashboardAndThenBookings,
  loadBookingsAndThenDashboard
}: AppHeaderTypes) => {
  const [dateRangeOpen, setDateRangeOpen] = useState(false);

  const location = useLocation();

  // states
  const dateRangeSelection = useSelector((state: any) => state.home.dateRangeSelection);
  const hotelName = useSelector((state: any) => state.home.hotelData?.hotelName);

  // actions
  const setDateRangeSelection = useActions(homeActionCreators?.setDateRangeSelectionAction, []);
  const loadBookings = useActions(bookingsActionCreators?.loadBookingsAction, []);
  const loadDashboardData = useActions(dashboardActionCreators?.loadDashboardDataAction, []);
  const loadOffers = useActions(offersActionCreators?.loadOffersAction, []);

  const shouldShowDatepicker =
    location?.pathname === `/${providerRootUrl}/` || location?.pathname === `/${providerRootUrl}/${pagesUrl.bookings}`;

  const shouldShowRefreshIcon: boolean = shouldShowRefreshPageIcon(location);

  const refreshOffers = () => {
    loadOffers(undefined, handleSectionLoader);
  };

  const toggleDateRange = () => {
    setDateRangeOpen(!dateRangeOpen);
  };

  const changeDateRange = (selectedDateRange: any) => {
    setDateRangeSelection({
      startDate: selectedDateRange.startDate,
      endDate: selectedDateRange.endDate
    });

    // urls with removed "/" at the and --> in order to compare with window pathname
    // window pathname with removed "/" at the and
    const locationUrlWithoutSlash = removeTrailingSlash(location?.pathname);
    // root url (Dashboard page) and /bookings url (Bookings page) with removed "/" at the and
    const rootUrlWithoutSlash = removeTrailingSlash(`/${providerRootUrl}`);
    const bookingsUrlWithoutSlash = removeTrailingSlash(`/${providerRootUrl}/${pagesUrl.bookings}`);
    //

    // In case window pathname is equal to root url (Dashboard page) load Dashboard data first, Bookings data second and trigger Global Loader only while loading Dashboard data
    if (locationUrlWithoutSlash === rootUrlWithoutSlash) {
      loadDashboardData(selectedDateRange, locationUrlWithoutSlash);
      loadBookings(selectedDateRange, locationUrlWithoutSlash);
    }
    // In case window pathname is equal to /bookings url (Bookings page) load Bookings data first, Dashboard data second and trigger Global Loader only while loading Bookings data
    if (locationUrlWithoutSlash === bookingsUrlWithoutSlash) {
      loadBookings(selectedDateRange, locationUrlWithoutSlash);
      loadDashboardData(selectedDateRange, locationUrlWithoutSlash);
    }
  };

  const handleApply = (event: any, picker: any) => changeDateRange(picker);

  const onRefreshButtonClick = () => {
    switch (location?.pathname) {
      case `/${providerRootUrl}/${pagesUrl.dashboard}`:
        loadDashboardAndThenBookings();
        break;
      case `/${providerRootUrl}/${pagesUrl.bookings}`:
        loadBookingsAndThenDashboard();
        break;
      case `/${providerRootUrl}/${pagesUrl.offers}`:
        refreshOffers();
        break;
      case `/${providerRootUrl}/${pagesUrl.logins}`:
        loadLogins();
        break;
      case `/${providerRootUrl}/${pagesUrl.settings}`:
        loadSettings(undefined);
        break;
      default:
        return null;
    }
  };

  return (
    <div className="header">
      <div className="header__content">
        <div className="logo__area">
          <img className="hotel-logo" src={getLocalLogoIcon} alt="logo" />
        </div>
        <div className="title__area">{hotelName}</div>
        <div className="action__area">
          {shouldShowDatepicker && (
            <DateRangePicker
              onApply={handleApply}
              ranges={ranges}
              autoApply={true}
              maxDate={moment()}
              startDate={dateRangeSelection?.startDate}
              endDate={dateRangeSelection?.endDate}
              maxSpan={{ months: 2 }}
            >
              <div className="date-range">
                <img
                  className="date-range__icon"
                  src={calendarSolidIcon}
                  onClick={toggleDateRange}
                  alt="date-range-icon"
                />
                <span onClick={toggleDateRange}>
                  {dateRangeSelection?.startDate?.format("DD.MM.YY") +
                    " - " +
                    dateRangeSelection?.endDate?.format("DD.MM.YY")}
                </span>
                {dateRangeOpen ? <div className="daterange-picker-component" /> : null}
              </div>
            </DateRangePicker>
          )}
          {shouldShowRefreshIcon && (
            <CustomTooltip title="Refresh page data" arrow>
              <div className="action__area__refresh-icon" onClick={onRefreshButtonClick} key={"refresh-page"}>
                <img src={refreshIcon} className="action__area__refresh-icon__img" alt="refresh-icon" />
              </div>
            </CustomTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
