import { hoursToMinutes } from "date-fns";
// helpers
import { getOptionsToSelect } from "../../common/utils/panel-helpers/helpers";
// global helpers
import { addSpaceAfterCommaRegex } from "@gl-shared/shared/helpers";

// Tooltips:

export const tooltip = {
  offerName: "Enter the name of the offer, like it will be displayed to the user.",
  duration: "If your offer has a set duration, please enter the number of minutes in here. E.g.: 1.5hrs = 90 mins",
  categories: "Your offer will be shown only in the selected categories.",
  links: "Please enter the links related to this offer",
  shortDescription: "Enter a teaser for your offer, typically 1-2 sentences.",
  longDescription:
    "Enter a detailed description of your offer, to inform the customer about everything that is relevant.",
  postBookingInfo:
    "This info will be displayed to the user after the booking and in the booking confirmation e-mail. E.g: “Please wear warm clothes”.",
  cancellation:
    "Enter your cancellation policy. This will be displayed to the user before and after the booking and is binding.",
  currency: "The default currency of your hotels destination is used and cannot be changed.",
  vat: "Please enter the VAT rate as a decimal number (e.g. 7.70)",
  priceType: "Please define the basic structure of your pricing.",
  basePrice: "Enter the price for the first sold unit (e.g. first person/hour/day/piece)",
  additionalUnitPrice: "Enter the price for every additional sold unit (e.g. every additional person/hour/day/piece)",
  priceDescription: 'Enter a description for this price (e.g. "Adults" or "15y and older")',
  allowChildren: "Do you allow children and children prices for your offer?",
  pricePerChild: "Enter the price for children",
  childPriceDescription: 'Enter a description for this price (e.g. "from 7-14y")',
  allowInfants: "Do you allow infants and infant prices for your offer?",
  pricePerInfant: "Enter the price for infants",
  infantPriceDescription: 'Enter a description for this price (e.g. "from 0-6y")',
  timeslots: "Please select, how we should generate the availability timeslots for your offer.",
  bookingDeadline: "This will determine, how far in advance this offer needs to be booked (cut-off time)",
  minAmount: "How many units must be booked at least during one booking?",
  maxAmount: "How many units can be booked in total during one booking?",
  allowGuestPlatform:
    "If turned on, then this offer will be available in the Guest Platform. You can also change this setting later in the Marketplace.",
  allowConcierge:
    "If turned on, then this offer will be available in the Concierge Platform. You can also change this setting later in the Marketplace.",
  instantConfirmation:
    "When your offer is set to have instant confirmation, the timeslots will be displayed as such (green) and the booking is confirmed directly, incl. an e-mail confirmation that is sent to the guest. If this is turned off, all bookings are handled as booking requests and must be manually fulfilled (confirmed or denied) by your team. Those offers will have yellow timeslot buttons.",
  sendCopyConfirmationTo:
    "All booking and cancellation confirmations will additionally be sent to this e-mail address (e.g. use this to be informed about bookings, especially if using instant confirmation).",
  sendBookingRequestTo:
    "Booking requests will be sent to this e-mail address. The recipient then needs to confirm or deny the request.",
  fulfilmentInstructions:
    'Here you can enter any instructions to your team on how to handle the booking request (e.g. "Please check availability with the F&B team – tel. 1234 – and confirm or deny request").',
  reservationSystem:
    "GET LOCAL can connect to reservation systems to check live availability and directly push bookings. Please enter the name your current reservation system you are using for this offer so we can check if we have an existing connection into said system. Please note: we can not guarantee that such a connection will be possible."
};

// Availability Timeslots Options By Type:

/*
Example:
  Monday: [
    { value: "09:30", label: "09:30" },
    { value: "10:00", label: "10:00" }
  ],
* */
export const timeslotsFixedInitialState = {
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
  Sunday: []
};

/*
Example:
  Monday: {
    from: [
      { value: "09:00", label: "09:00" },
      { value: "11:30", label: "11:30" }
    ],
    to: [
      { value: "10:00", label: "10:00" },
      { value: "12:30", label: "12:30" }
    ]
  }
* */

export const timeslotsIntervalInitialState = {
  Monday: {
    from: [],
    to: []
  },
  Tuesday: {
    from: [],
    to: []
  },
  Wednesday: {
    from: [],
    to: []
  },
  Thursday: {
    from: [],
    to: []
  },
  Friday: {
    from: [],
    to: []
  },
  Saturday: {
    from: [],
    to: []
  },
  Sunday: {
    from: [],
    to: []
  }
};

export const timeslotsPerDayInitialState = {
  Monday: { inputValues: [{ value: "23:59", label: "23:59" }], toggle: false },
  Tuesday: { inputValues: [{ value: "23:59", label: "23:59" }], toggle: false },
  Wednesday: { inputValues: [{ value: "23:59", label: "23:59" }], toggle: false },
  Thursday: { inputValues: [{ value: "23:59", label: "23:59" }], toggle: false },
  Friday: { inputValues: [{ value: "23:59", label: "23:59" }], toggle: false },
  Saturday: { inputValues: [{ value: "23:59", label: "23:59" }], toggle: false },
  Sunday: { inputValues: [{ value: "23:59", label: "23:59" }], toggle: false }
};

// END OF Availability Timeslots Options By Type:

// Other methods and data:

export const hotelPriceTypeOptions = [
  { value: "per_person", label: "per person" },
  { value: "per_piece", label: "per piece" },
  { value: "per_day", label: "per day" },
  { value: "per_hour", label: "per hour" }
];

export const offersToAddLimit = 3;

export const offersStep = {
  offerDetails: "offerDetails",
  pricing: "pricing",
  availability: "availability",
  photos: "photos",
  fulfilment: "fulfilment",
  summary: "summary"
};

export const offersSteps = [
  { step: offersStep.offerDetails, properStepName: "OFFER DETAILS" },
  { step: offersStep.pricing, properStepName: "PRICING" },
  { step: offersStep.availability, properStepName: "AVAILABILITY" },
  { step: offersStep.photos, properStepName: "PHOTOS" },
  { step: offersStep.fulfilment, properStepName: "FULFILMENT" },
  { step: offersStep.summary, properStepName: "SUMMARY" }
];

// Options for Timeslots
export const timeslotsOptions = [
  { type: "fixed", eventType: 1, value: "fixed", label: "fixed timeslots", increment: 30 },
  { type: "onePerAllDay", eventType: 2, value: "onePerAllDay", label: "one per day (all day)", increment: 30 },
  { type: "timeslotIntervals", eventType: 3, value: "every15minutes", label: "every 15 minutes", increment: 15 },
  { type: "timeslotIntervals", eventType: 4, value: "every30minutes", label: "every 30 minutes", increment: 30 },
  { type: "timeslotIntervals", eventType: 5, value: "every60minutes", label: "every 60 minutes", increment: 60 }
];

export const createOfferDropzoneOptions = {
  maxSize: 10000000,
  maxFiles: 5,
  acceptedFiles: {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"]
  }
};

export const steps = ["offerDetails", "pricing", "availability", "photos", "fulfilment", "summary"];

export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const getFormattedBookingDeadlineOptions = (arrayOfValuesForFormatting, stringAfterValues) => {
  let arr = [];
  for (let i = 0; i < arrayOfValuesForFormatting?.length; i++) {
    arr.push({
      value: arrayOfValuesForFormatting[i],
      label: arrayOfValuesForFormatting[i].toString() + stringAfterValues
    });
  }
  return arr;
};

// Options for BookingDeadline
export const bookingDeadlineOptions = getFormattedBookingDeadlineOptions([0, 1, 2, 3, 5, 12, 24, 48], "h");

export const minMaxAmountOptions = getOptionsToSelect(1, 10);

export const hotel_pricing_placeholder_obj = {
  base_price: null,
  infant_price: null,
  child_price: null,
  additional_unit_price: null,
  base_price_description: "",
  child_price_description: "",
  infant_price_description: "",
  allow_infants: false,
  allow_children: false
};

export const offerStepsPlaceholder = {
  offerDetailsData: {
    event_code: "",
    name: "",
    duration_in_minutes: "",
    event_categories: [],
    short_description: "",
    long_description: "",
    post_booking_info: "",
    cancellation_policy: "",
    links: []
  },
  pricingData: {
    vat: "",
    price_type: hotelPriceTypeOptions[0],
    hotel_pricing: hotel_pricing_placeholder_obj
  },
  availabilityData: {
    event_type: timeslotsOptions[0], // this is "Timeslots"
    booking_deadline_in_hours: bookingDeadlineOptions[0],
    minimum_units: minMaxAmountOptions[0],
    maximum_units: minMaxAmountOptions[minMaxAmountOptions?.length - 1],
    channel_hotel_guest_platform: true,
    channel_hotel_concierge: true,
    timeslotsFixedType: timeslotsFixedInitialState,
    timeslotsIntervalType: timeslotsIntervalInitialState,
    timeslotsPerDayType: timeslotsPerDayInitialState
  },
  photosData: [],
  fulfilmentData: {
    directly_bookable: true,
    booking_confirmation_copy_recipients: "",
    bookingRequestTo: "",
    service_provider_instructions: "",
    reservationSystem: ""
  }
};

export const getCheckedCategoryIds = (allCategories, hotelCategoryIds) => {
  if (allCategories?.length > 0) {
    return allCategories
      .filter(allCat => hotelCategoryIds.find(catId => catId === allCat.id))
      .map(filteredCat => filteredCat.id);
  }
};

export const updateCategoryIds = (categoryId, checkedCategoryIds) => {
  const exists = checkedCategoryIds.includes(categoryId);

  if (exists) {
    return checkedCategoryIds.filter(id => id !== categoryId);
  } else {
    return [...checkedCategoryIds, categoryId];
  }
};

export const getReformattedPhotos = photosResponse => {
  if (photosResponse?.length === 0) {
    return [];
  } else {
    return photosResponse.map(photo => {
      return {
        ...photo,
        path: photo.id
      };
    });
  }
};

// Hotel Offers: returns object with Offer data formatted, for each from 5 steps
export const getEditOfferDataFormattedBySteps = offer => {
  const formatMinUnits = getOptionsToSelect(offer?.minimum_units, offer?.minimum_units);
  const formatMaxUnits = getOptionsToSelect(offer?.maximum_units, offer?.maximum_units);
  const formatDeadlineOptions = getFormattedBookingDeadlineOptions([offer?.booking_deadline_in_hours], "h");
  const getTimeslot = timeslotsOptions.find(slot => slot.eventType === offer?.event_type);

  return {
    offerDetailsData: {
      event_code: offer?.event_code,
      name: offer?.name,
      duration_in_minutes: offer?.duration_in_minutes,
      event_categories:
        (offer?.event_categories?.length > 0 && offer?.event_categories.map(category => category.id)) || [],
      short_description: offer?.short_description,
      long_description: offer?.long_description,
      post_booking_info: offer?.post_booking_info,
      cancellation_policy: offer?.cancellation_policy,
      links: offer?.links
    },
    pricingData: {
      vat: Number(offer?.vat).toFixed(2),
      price_type: hotelPriceTypeOptions.find(type => type.value === offer?.price_type),
      hotel_pricing: offer?.hotel_pricing
        ? {
            ...offer?.hotel_pricing,
            infant_price: Number(offer?.hotel_pricing?.infant_price).toFixed(2),
            base_price: Number(offer?.hotel_pricing?.base_price).toFixed(2),
            additional_unit_price: Number(offer?.hotel_pricing?.additional_unit_price).toFixed(2),
            allow_children: offer?.hotel_pricing?.allow_children,
            child_price: Number(offer?.hotel_pricing?.child_price).toFixed(2)
          }
        : hotel_pricing_placeholder_obj
    },
    availabilityData: {
      event_type: getTimeslot,
      booking_deadline_in_hours: formatDeadlineOptions[0],
      minimum_units: formatMinUnits[0],
      maximum_units: formatMaxUnits[0],
      channel_hotel_guest_platform: offer?.channels[0]?.channel_hotel_guest_platform,
      channel_hotel_concierge: offer?.channels[0]?.channel_hotel_concierge,
      timeslotsFixedType:
        getTimeslot.eventType === 1
          ? formatResponseToExpectedScheduleFormat(getTimeslot.eventType, offer?.schedule.schedule_as_array)
          : timeslotsFixedInitialState,
      timeslotsIntervalType:
        getTimeslot.eventType === 3 || getTimeslot.eventType === 4 || getTimeslot.eventType === 5
          ? formatResponseToExpectedScheduleFormat(getTimeslot.eventType, offer?.schedule.schedule_as_array)
          : timeslotsIntervalInitialState,
      timeslotsPerDayType:
        getTimeslot.eventType === 2
          ? formatResponseToExpectedScheduleFormat(getTimeslot.eventType, offer?.schedule.schedule_as_array)
          : timeslotsPerDayInitialState
    },
    photosData: getReformattedPhotos(offer?.images),
    fulfilmentData: {
      directly_bookable: offer?.directly_bookable,
      booking_confirmation_copy_recipients: offer?.booking_confirmation_copy_recipients[0],
      bookingRequestTo: offer?.direct_processing_recipients[0],
      service_provider_instructions: offer?.service_provider_instructions,
      reservationSystem: offer?.reservation?.reservation_system
    }
  };
};

export const getOptionsWithIncrementForDropdown = incrementValue => {
  let increment = 0;

  let arr = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60 / incrementValue; j++) {
      arr.push({
        value:
          i < 10
            ? "0" + i.toString() + ":" + `${increment < 10 ? "0" + increment : increment}`
            : i.toString() + ":" + `${increment < 10 ? "0" + increment : increment}`,
        label:
          i < 10
            ? "0" + i.toString() + ":" + `${increment < 10 ? "0" + increment : increment}`
            : i.toString() + ":" + `${increment < 10 ? "0" + increment : increment}`
      });

      if (increment === 60 - incrementValue) {
        increment = 0;
      } else {
        increment = increment + incrementValue;
      }
    }
  }

  return arr;
};

export const getStepsToValidate = selectedStep => {
  return steps.slice(0, steps.indexOf(selectedStep));
};

export const validationStepMatchCheck = arr => {
  return arr.find(f => f === false) === undefined;
};

export const getFormattedScheduleRequest = (eventType, schedule) => {
  // if eventType is "fixed"
  if (eventType === 1) {
    const weekObj = Object.entries(schedule).map(([key, value]) => {
      return {
        [key]: value.map(t => t.value).join(",")
      };
    });

    return Object.assign({ CLOSING_DAYS: null }, ...weekObj);
  }

  // if eventType is "onePerAllDay"
  if (eventType === 2) {
    const weekObj = Object.entries(schedule).map(([key, value]) => {
      return {
        [key]: value.toggle ? value.inputValues.map(t => `00:00-${t.value}`)[0] : ""
      };
    });

    return Object.assign({ CLOSING_DAYS: null }, ...weekObj);
  }

  // if eventType is "timeslotIntervals"
  if (eventType === 3 || eventType === 4 || eventType === 5) {
    const weekObj = Object.entries(schedule).map(([key, value]) => {
      return {
        [key]: value.from.map((valFrom, i) => `${valFrom.value}-${value.to[i].value}`).join(",")
      };
    });

    return Object.assign({ CLOSING_DAYS: null }, ...weekObj);
  }
};

// Availability timeslots: format schedule.schedule_as_array (response from GET Hotel Offer) to expected structure for each event_type
export const formatResponseToExpectedScheduleFormat = (eventType, schedule) => {
  // if eventType is "fixed"
  if (eventType === 1) {
    const scheduledArr = Object.entries(schedule).filter(([key, value]) => key !== "CLOSING_DAYS");
    let fixedTimeslotsObj = {};

    scheduledArr.forEach(([key, value]) => {
      return (fixedTimeslotsObj = {
        ...fixedTimeslotsObj,
        [key]: value?.length
          ? value.split(",").map(s => {
              return {
                label: s,
                value: s
              };
            })
          : []
      });
    });

    return fixedTimeslotsObj;
  }

  // if eventType is "onePerAllDay"
  if (eventType === 2) {
    const scheduledArr = Object.entries(schedule).filter(([key, value]) => key !== "CLOSING_DAYS");
    let perDaySlotsObj = {};

    scheduledArr.forEach(([key, value]) => {
      return (perDaySlotsObj = {
        ...perDaySlotsObj,
        [key]:
          value?.length > 0
            ? {
                inputValues: [
                  {
                    value: value.split("-")[1],
                    label: value.split("-")[1]
                  }
                ],
                toggle: true
              }
            : {
                inputValues: [
                  {
                    value: "23:59",
                    label: "23:59"
                  }
                ],
                toggle: false
              }
      });
    });

    return perDaySlotsObj;
  }

  // if eventType is "timeslotIntervals"
  if (eventType === 3 || eventType === 4 || eventType === 5) {
    const scheduledArr = Object.entries(schedule).filter(([key, value]) => key !== "CLOSING_DAYS");
    let intervalObj = {};

    scheduledArr.forEach(([key, value]) => {
      return (intervalObj = {
        ...intervalObj,
        [key]:
          value?.length > 0
            ? {
                from: value.split(",").map(a => {
                  return {
                    value: a.split("-")[0],
                    label: a.split("-")[0]
                  };
                }),
                to: value.split(",").map(a => {
                  return {
                    value: a.split("-")[1],
                    label: a.split("-")[1]
                  };
                })
              }
            : {
                from: [],
                to: []
              }
      });
    });

    return intervalObj;
  }
};

export const leaveProcessNoticeMsg =
  "Are you sure you want to leave this process? All settings for the offer will be lost!";

export const changeAvailabilityTimeslotTypeMsg =
  "Your current selection of timeslots will be lost when you change the timeslot type. Are you sure?";

export const checkIntervalValidity = (from, to) => {
  const fromHours = from.split(":")[0];
  const toHours = to.split(":")[0];
  const fromMinutes = from.split(":")[1];
  const toMinutes = to.split(":")[1];

  return !(
    Number(hoursToMinutes(fromHours)) + Number(fromMinutes) >=
    Number(hoursToMinutes(toHours)) + Number(toMinutes)
  );
};

export const isTimeslotOverlap = (fromCurrent, toCurrent, arr) => {
  // Indexes array to push index of every timeslot which overlaps with current (the one we are checking)
  const indexes = [];

  // Current timeslots hours converted to minutes
  const fromHoursConvertedToMinutes = Number(hoursToMinutes(fromCurrent.split(":")[0]));
  const toHoursConvertedToMinutes = Number(hoursToMinutes(toCurrent.split(":")[0]));
  // Current timeslots minutes
  const fromMinutes = Number(fromCurrent.split(":")[1]);
  const toMinutes = Number(toCurrent.split(":")[1]);
  // Current timeslots interval in minutes (from and to in minutes)
  const from = fromHoursConvertedToMinutes + fromMinutes;
  const to = toHoursConvertedToMinutes + toMinutes;

  for (let i = 0; i < arr["from"].length; i++) {
    // check for true state in conditions to set current timeslot overlap to true
    if (
      // check if current timeslot part FROM is in range or within other timeslots interval
      (from > Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) &&
        from < Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1])) ||
      // check if current timeslot part TO is in range or within other timeslots interval considering that current FROM is less than TO (valid slot)
      (to > Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) &&
        to <= Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1]) &&
        from < to) ||
      // check if current timeslot FROM (FROM could be equal to other timeslot from) and TO is overlapping other timeslot considering that timeslot TO is greater than FROM (valid slot)
      (from <= Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) &&
        to > Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1]) &&
        Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1]) >
          Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1])) ||
      // check if current timeslot is overlapping other timeslot considering that current timeslot FROM is less than other timeslots FROM (valid slot)
      (from < Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) &&
        to > Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1]) &&
        to > Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1])) ||
      // check for duplicates
      (from === Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) &&
        to === Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1])) ||
      // check for duplicates for timeslots that are overlap range of other timeslots
      (from === to &&
        to > Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) &&
        to < Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1])) ||
      (Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) ===
        Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1]) &&
        from < Number(hoursToMinutes(arr.from[i].value.split(":")[0])) + Number(arr.from[i].value.split(":")[1]) &&
        to > Number(hoursToMinutes(arr.to[i].value.split(":")[0])) + Number(arr.to[i].value.split(":")[1]))
    ) {
      indexes.push(i);
    }
  }
  return indexes?.length > 0;
};

export const valueHasDuplicatesWithinValueKeys = (arr, value) => {
  for (let i = 0; i < arr.length; i++) {
    if (value === arr[i].value) {
      return true;
    }
  }

  return false;
};

export const checkErrorStatusForAllDays = errorFieldsByDay => {
  for (let i = 0; i <= days.length; i++) {
    if (errorFieldsByDay[days?.[i]]?.error === true) {
      return true;
    }
  }

  return false;
};

export const checkErrorValidationForAllDays = arr => {
  for (let i = 0; i <= days.length; i++) {
    if (arr[days[i]]?.from?.find(f => f.errorValidationMsg)) {
      if (arr[days[i]]?.from?.find(f => f.errorValidationMsg)?.errorValidationMsg !== "") {
        return true;
      }
    }
  }
  return false;
};

export const checkIntervalSlotsErrorByDay = errorFieldsByDay => {
  for (let i = 0; i <= errorFieldsByDay["from"].length; i++) {
    if (errorFieldsByDay.from[i]?.errorValidationMsg) {
      if (errorFieldsByDay.from[i]?.errorValidationMsg !== "") {
        return true;
      }
    }
  }
  return false;
};

export const checkErrorStatusByDay = arr => {
  for (let i = 0; i <= arr.length; i++) {
    if (arr[i]?.error === true) {
      return true;
    }
  }

  return false;
};

export const overlapCheck = timeslotsPerDay => {
  // Check for overlapping within timeslots
  let indexes = [];
  for (let i = 0; i < timeslotsPerDay["from"].length; i++) {
    const filteredFrom = timeslotsPerDay["from"].filter((f, index) => index !== i);
    const filteredTo = timeslotsPerDay["to"].filter((f, index) => index !== i);
    const timeslotsIntervalTypeFromSelectedDay = { from: filteredFrom, to: filteredTo };

    if (
      isTimeslotOverlap(
        timeslotsPerDay.from[i].value,
        timeslotsPerDay.to[i].value,
        timeslotsIntervalTypeFromSelectedDay
      )
    ) {
      indexes.push(i);
    }
  }
  return indexes;
};

// Availability: Check are there added timeslots for selected event type (returns true || false)
export const checkAreTimeslotsDataAdded = (timeslots, selectedEventType) => {
  let result;
  // if eventType === 1
  if (selectedEventType === "fixed") {
    result = Object.entries(timeslots).find(([key, value]) => value?.length > 0);
  }

  // if eventType === 2
  if (selectedEventType === "onePerAllDay") {
    result = Object.entries(timeslots).find(([key, value]) => value.toggle);
  }

  // if eventType === 3 || 4 || 5
  if (selectedEventType === "timeslotIntervals") {
    result = Object.entries(timeslots).find(([key, value]) => value.from.length > 0);
  }

  return result?.length > 0;
};

export const getFormattedTimeValue = (hours, minutes) => {
  return hours < 10
    ? "0" + hours.toString() + ":" + `${minutes < 10 ? "0" + minutes : minutes}`
    : hours.toString() + ":" + `${minutes < 10 ? "0" + minutes : minutes}`;
};

export const shouldShowStepCheckmarkIcon = (step, checkmarkCondition) => {
  if (step === offersStep.summary) {
    return false;
  }
  return !!checkmarkCondition;
};

export const getSummaryBoxContainerItems = (column, data) => {
  const items = [];
  if (column === "detailsAndPricing") {
    items.push(
      {
        label: "Name",
        value: data.offerDetailsData.name
      },
      {
        label: "Price Type",
        value: data.pricingData.price_type.label
      },
      {
        label: "Base Price",
        value: `${data.hotelCurrency} ${data.pricingData?.hotel_pricing?.base_price}`
      },
      {
        label: "Additional Unit Price",
        value: `${data.hotelCurrency} ${data.pricingData.hotel_pricing?.additional_unit_price}`
      },
      {
        label: "VAT",
        value: data.pricingData.vat
      }
    );

    if (data.pricingData?.price_type.value === "per_person") {
      if (data.pricingData?.hotel_pricing?.allow_children) {
        items.splice(4, 0, {
          label: "Children Price",
          value: `${data.hotelCurrency} ${data.pricingData?.hotel_pricing?.child_price}`
        });
      }
      if (data.pricingData?.hotel_pricing?.allow_children && data.pricingData?.hotel_pricing?.allow_infants) {
        const spliceStartPosition = data.pricingData?.hotel_pricing?.allow_children ? 5 : 4;
        items.splice(spliceStartPosition, 0, {
          label: "Infant Price",
          value: `${data.hotelCurrency} ${data.pricingData?.hotel_pricing?.infant_price}`
        });
      }
    }
  } else if (column === "availability") {
    items.push(
      {
        label: "Timeslots",
        value: data.availabilityData.event_type.label
      },
      {
        label: "Instant confirmation",
        value: data.fulfilmentData.directly_bookable ? "yes" : "no"
      },
      {
        label: "Booking deadline",
        value: `${data.availabilityData.booking_deadline_in_hours.value} ${
          data.availabilityData.booking_deadline_in_hours.value < 2 ? "hour" : "hours"
        }`
      },
      {
        label: "Minimum Amount",
        value: data.availabilityData.minimum_units.value
      },
      {
        label: "Maximum Amount",
        value: data.availabilityData.maximum_units.value
      }
    );
  } else if (column === "timeslots") {
    let scheduleToFormat;
    if (data.availabilityData?.event_type.eventType === 1) {
      scheduleToFormat = data.availabilityData?.timeslotsFixedType;
    } else if (data.availabilityData?.event_type.eventType === 2) {
      scheduleToFormat = data.availabilityData?.timeslotsPerDayType;
    } else {
      scheduleToFormat = data.availabilityData?.timeslotsIntervalType;
    }
    const formattedTimeslots = getFormattedScheduleRequest(
      data.availabilityData?.event_type.eventType,
      scheduleToFormat
    );

    items.push(
      {
        label: "Monday",
        value: addSpaceAfterCommaRegex(formattedTimeslots.Monday)
      },
      {
        label: "Tuesday",
        value: addSpaceAfterCommaRegex(formattedTimeslots.Tuesday)
      },
      {
        label: "Wednesday",
        value: addSpaceAfterCommaRegex(formattedTimeslots.Wednesday)
      },
      {
        label: "Thursday",
        value: addSpaceAfterCommaRegex(formattedTimeslots.Thursday)
      },
      {
        label: "Friday",
        value: addSpaceAfterCommaRegex(formattedTimeslots.Friday)
      },
      {
        label: "Saturday",
        value: addSpaceAfterCommaRegex(formattedTimeslots.Saturday)
      },
      {
        label: "Sunday",
        value: addSpaceAfterCommaRegex(formattedTimeslots.Sunday)
      }
    );
  }

  return items;
};

// TODO: CONSIDER ADDING IMPROVED LOGIC AND CHECKING TYPE OF TIMESLOTS FOR EVERY TYPE DEFINED
export const checkForCustomTimeslotType = (slots, type) => {
  for (let i = 0; i <= days.length; i++) {
    if (type === "fixed") {
      if (slots[days[i]]?.find(timeslot => timeslot?.value?.split(":")?.length > 2)) {
        return true;
      }
    } else if (type === "timeslotIntervals") {
      if (slots[days[i]]?.to?.find(timeslot => timeslot?.value === undefined)) {
        return true;
      }
    } else {
      if (slots?.[days[i]]?.inputValues?.find(timeslot => timeslot?.value?.split(":")?.length > 2)) {
        return true;
      }
    }
  }
  return false;
};
