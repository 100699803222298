// helpers
import { pagesUrl, appSection, providerRootUrl } from "../../utils/panel-helpers/helpers";
import ApiHelper from "../../utils/apiHelper";
import {
  checkErrorStatusByDay,
  checkIntervalSlotsErrorByDay,
  checkIntervalValidity,
  days,
  getFormattedScheduleRequest,
  getStepsToValidate,
  offersStep,
  offerStepsPlaceholder,
  overlapCheck,
  steps,
  timeslotsFixedInitialState,
  timeslotsIntervalInitialState,
  timeslotsPerDayInitialState,
  validationStepMatchCheck,
  valueHasDuplicatesWithinValueKeys
} from "../../../pages/Offers/offersHelpers";
// actions
import { globalActionCreators } from "./globalModule";
import { errorFieldActionCreators } from "./errorFieldModule";

export const offersActionTypes = {
  GET_OFFERS: "GET_OFFERS",
  SET_SELECTED_STEP: "SET_SELECTED_STEP",
  SET_VALIDATED_STEPS: "SET_VALIDATED_STEPS",
  UPDATE_DETAILS_STEP: "UPDATE_DETAILS_STEP",
  UPDATE_PRICING_STEP: "UPDATE_PRICING_STEP",
  UPDATE_FULFILMENT_STEP: "UPDATE_FULFILMENT_STEP",
  UPDATE_PHOTOS_STEP: "UPDATE_PHOTOS_STEP",
  SET_OFFER_FOR_EDIT: "SET_OFFER_FOR_EDIT",
  RESET_OFFER_STEP_DATA: "RESET_OFFER_STEP_DATA",
  RESET_AVAILABILITY_TIMESLOTS_DATA: "RESET_AVAILABILITY_TIMESLOTS_DATA",
  RESET_AVAILABILITY_TIMESLOTS_ERRORS: "RESET_AVAILABILITY_TIMESLOTS_ERRORS",
  UPDATE_AVAILABILITY_STEP: "UPDATE_AVAILABILITY_STEP",
  TOGGLE_STEP_LOADER: "TOGGLE_STEP_LOADER",
  PHOTO_IDS_REMOVE: "PHOTO_IDS_REMOVE",
  SET_TIMESLOTS_ERROR_MSG: "SET_TIMESLOTS_ERROR_MSG",
  SET_TIMESLOTS_ERROR_MSG_INT: "SET_TIMESLOTS_ERROR_MSG_INT",
  SET_TIMESLOTS_ERROR_MSG_FIX: "SET_TIMESLOTS_ERROR_MSG_FIX",
  UPDATE_TIMESLOTS_ERRORS: "UPDATE_TIMESLOTS_ERRORS",
  SET_IS_FORM_CHANGED_STATE: "SET_IS_FORM_CHANGED_STATE"
};

export const initialState = {
  panelOffers: null,
  selectedStep: offersStep?.offerDetails,
  offerStepLoader: false,
  offerDetailsData: offerStepsPlaceholder.offerDetailsData,
  pricingData: offerStepsPlaceholder.pricingData,
  availabilityData: offerStepsPlaceholder.availabilityData,
  photosData: offerStepsPlaceholder.photosData,
  photoIdsForRemove: [],
  fulfilmentData: offerStepsPlaceholder.fulfilmentData,
  validatedSteps: [],
  availabilityErrorMsg: null,
  availabilityErrorIntervals: {
    Monday: { from: [], to: [] },
    Tuesday: { from: [], to: [] },
    Wednesday: { from: [], to: [] },
    Thursday: { from: [], to: [] },
    Friday: { from: [], to: [] },
    Saturday: { from: [], to: [] },
    Sunday: { from: [], to: [] }
  },
  availabilityErrorFixed: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
  },
  isFormChanged: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case offersActionTypes.GET_OFFERS:
      return {
        ...state,
        panelOffers: action.panelOffers
      };
    case offersActionTypes.SET_SELECTED_STEP:
      return {
        ...state,
        selectedStep: action.selectedStep
      };
    case offersActionTypes.SET_VALIDATED_STEPS:
      return {
        ...state,
        validatedSteps: action.validatedSteps
      };
    case offersActionTypes.SET_OFFER_FOR_EDIT:
      return {
        ...state,
        offerDetailsData: action.offerDetailsData,
        pricingData: action.pricingData,
        availabilityData: action.availabilityData,
        photosData: action.photosData,
        fulfilmentData: action.fulfilmentData
      };
    case offersActionTypes.RESET_OFFER_STEP_DATA:
      return {
        ...state,
        offerDetailsData: offerStepsPlaceholder.offerDetailsData,
        pricingData: offerStepsPlaceholder.pricingData,
        availabilityData: offerStepsPlaceholder.availabilityData,
        photosData: offerStepsPlaceholder.photosData,
        fulfilmentData: offerStepsPlaceholder.fulfilmentData,
        photoIdsForRemove: [],
        validatedSteps: [],
        selectedStep: offersStep?.offerDetails
      };
    case offersActionTypes.RESET_AVAILABILITY_TIMESLOTS_DATA:
      return {
        ...state,
        availabilityData: {
          ...state.availabilityData,
          timeslotsFixedType: timeslotsFixedInitialState,
          timeslotsIntervalType: timeslotsIntervalInitialState,
          timeslotsPerDayType: timeslotsPerDayInitialState
        }
      };
    case offersActionTypes.RESET_AVAILABILITY_TIMESLOTS_ERRORS:
      const availabilityErrorIntervals = {
        Monday: { from: [], to: [] },
        Tuesday: { from: [], to: [] },
        Wednesday: { from: [], to: [] },
        Thursday: { from: [], to: [] },
        Friday: { from: [], to: [] },
        Saturday: { from: [], to: [] },
        Sunday: { from: [], to: [] }
      };
      const availabilityErrorFixed = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: []
      };

      return {
        ...state,
        availabilityErrorFixed: availabilityErrorFixed,
        availabilityErrorIntervals: availabilityErrorIntervals
      };
    case offersActionTypes.UPDATE_DETAILS_STEP:
      return {
        ...state,
        offerDetailsData: action.offerDetailsData
      };
    case offersActionTypes.UPDATE_PRICING_STEP:
      return {
        ...state,
        pricingData: action.pricingData
      };
    case offersActionTypes.UPDATE_FULFILMENT_STEP:
      return {
        ...state,
        fulfilmentData: action.fulfilmentData
      };
    case offersActionTypes.UPDATE_PHOTOS_STEP:
      return {
        ...state,
        photosData: action.photosData
      };
    case offersActionTypes.UPDATE_AVAILABILITY_STEP:
      return {
        ...state,
        availabilityData: action.availabilityData
      };
    case offersActionTypes.SET_IS_FORM_CHANGED_STATE:
      return {
        ...state,
        isFormChanged: action.isFormChanged
      };
    case offersActionTypes.TOGGLE_STEP_LOADER:
      return {
        ...state,
        offerStepLoader: action.offerStepLoader
      };
    case offersActionTypes.PHOTO_IDS_REMOVE:
      // add item to remove only if its id type is number
      // only photos with id type number are photos that were uploaded before and could be removed from EP
      const idForRemoving = typeof action.photoIdsForRemove === "number" && action.photoIdsForRemove;
      return {
        ...state,
        photoIdsForRemove: idForRemoving ? [...state.photoIdsForRemove, idForRemoving] : [...state.photoIdsForRemove]
      };
    case offersActionTypes.SET_TIMESLOTS_ERROR_MSG:
      return {
        ...state,
        availabilityErrorMsg: action.availabilityErrorMsg
      };
    case offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT:
      return {
        ...state,
        availabilityErrorIntervals: action.availabilityErrorIntervals,
        availabilityErrorMsg: action.availabilityErrorMsg
      };
    case offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX:
      return {
        ...state,
        availabilityErrorFixed: action.availabilityErrorFixed,
        availabilityErrorMsg: action.availabilityErrorMsg
      };
    case offersActionTypes.UPDATE_TIMESLOTS_ERRORS:
      return {
        ...state,
        availabilityErrorFixed: action.availabilityErrorFixed,
        availabilityErrorMsg: action.availabilityErrorMsg
      };

    default:
      return state;
  }
};

export const offersActionCreators = {
  loadOffersAction: (hotelId, callSectionLoader) => async (dispatch, getState) => {
    const sectionLoadingNamesState = getState().global.sectionLoadingNames;
    if (sectionLoadingNamesState?.includes(appSection?.offers)) {
      // disable new API call if we are still waiting for response
      return;
    }

    callSectionLoader(true, appSection?.offers);

    try {
      const res = await ApiHelper.get(`/v1/service-provider-offers`);

      dispatch({
        type: offersActionTypes.GET_OFFERS,
        panelOffers: res?.data?.length > 0 ? res?.data : []
      });
    } catch (error) {
      dispatch(globalActionCreators.handleErrorAction());
    } finally {
      callSectionLoader(false, appSection?.offers);
    }
  },
  // action for CREATE new or EDIT existing Offer
  saveOfferAction: (onHandleFormMessage, onLoadOffers, currency, isEdit, navigate) => async (dispatch, getState) => {
    const offerDetailsState = getState().offers.offerDetailsData;
    const pricingState = getState().offers.pricingData;
    const availabilityState = getState().offers.availabilityData;
    const photosState = getState().offers.photosData;
    const photoIdsForRemoveState = getState().offers.photoIdsForRemove;
    const fulfilmentState = getState().offers.fulfilmentData;

    // turn on step loader
    dispatch({
      type: offersActionTypes.TOGGLE_STEP_LOADER,
      offerStepLoader: true
    });

    const isSelectedPriceTypePerPerson = pricingState?.price_type.value === "per_person";

    try {
      // infos
      let bodyEventRequest = {
        name: offerDetailsState?.name,
        durationInMinutes: Number(offerDetailsState?.duration_in_minutes),
        shortDescription: offerDetailsState?.short_description,
        longDescription: offerDetailsState?.long_description,
        postBookingInfo: offerDetailsState?.post_booking_info,
        cancellationPolicy: offerDetailsState?.cancellation_policy,
        links: offerDetailsState?.links,
        currency: currency,
        eventType: availabilityState?.event_type.eventType,
        vat: pricingState?.vat,
        priceType: pricingState?.price_type.value,
        bookingDeadlineInHours: availabilityState?.booking_deadline_in_hours?.value,
        minimumUnits: availabilityState?.minimum_units?.value,
        maximumUnits: availabilityState?.maximum_units?.value,
        directlyBookable: fulfilmentState?.directly_bookable,
        serviceProviderInstructions: fulfilmentState?.service_provider_instructions,
        bookingConfirmationCopyRecipients: [fulfilmentState?.booking_confirmation_copy_recipients],
        directProcessingRecipients: fulfilmentState?.bookingRequestTo ? [fulfilmentState?.bookingRequestTo] : []
      };

      isEdit && (bodyEventRequest.eventCode = offerDetailsState?.event_code);

      let hotelPricingRequest = {
        base_price: Number(pricingState?.hotel_pricing?.base_price).toString(),
        additional_unit_price: Number(pricingState?.hotel_pricing?.additional_unit_price).toString(),
        allow_children: isSelectedPriceTypePerPerson ? pricingState?.hotel_pricing?.allow_children : false,
        child_price:
          isSelectedPriceTypePerPerson && pricingState?.hotel_pricing?.allow_children
            ? Number(pricingState?.hotel_pricing?.child_price).toString()
            : null,
        base_price_description: pricingState?.hotel_pricing?.base_price_description,
        child_price_description: pricingState?.hotel_pricing?.child_price_description,
        infant_price_description: pricingState?.hotel_pricing?.infant_price_description,
        allow_infants:
          isSelectedPriceTypePerPerson && pricingState?.hotel_pricing?.allow_children
            ? pricingState?.hotel_pricing?.allow_infants
            : false,
        infant_price:
          isSelectedPriceTypePerPerson &&
          pricingState?.hotel_pricing?.allow_children &&
          pricingState?.hotel_pricing?.allow_infants
            ? Number(pricingState?.hotel_pricing?.infant_price).toString()
            : null
      };

      let scheduleToFormat;
      if (availabilityState?.event_type.eventType === 1) {
        scheduleToFormat = availabilityState?.timeslotsFixedType;
      } else if (availabilityState?.event_type.eventType === 2) {
        scheduleToFormat = availabilityState?.timeslotsPerDayType;
      } else {
        scheduleToFormat = availabilityState?.timeslotsIntervalType;
      }
      // timeslots
      const bodyScheduleRequest = {
        scheduleAsArray: getFormattedScheduleRequest(availabilityState?.event_type.eventType, scheduleToFormat)
      };
      // categories
      const bodyCategoryRequest = {
        categoriesIds: offerDetailsState?.event_categories
      };
      // for EDIT
      const bodyAdditionalDataRequest = {
        removedEventFilesIds: photoIdsForRemoveState
      };

      let bookingDetailsFormData = new FormData();

      // for photos
      if (isEdit) {
        const filteredNewPhotosForUpload = photosState?.length > 0 && photosState.filter(photo => !photo.id);
        filteredNewPhotosForUpload?.length > 0 &&
          filteredNewPhotosForUpload.forEach(file => {
            bookingDetailsFormData.append("eventFile[]", file);
          });
      } else {
        photosState?.length > 0 &&
          photosState.forEach(file => {
            bookingDetailsFormData.append("eventFile[]", file);
          });
      }
      bookingDetailsFormData.append("eventRequest", JSON.stringify(bodyEventRequest));
      bookingDetailsFormData.append("hotel_pricing", JSON.stringify(hotelPricingRequest));
      bookingDetailsFormData.append("scheduleRequest", JSON.stringify(bodyScheduleRequest));
      bookingDetailsFormData.append("categoryRequest", JSON.stringify(bodyCategoryRequest));
      // additionalDataRequest is necessary only for EDIT Offer Call
      isEdit && bookingDetailsFormData.append("additionalDataRequest", JSON.stringify(bodyAdditionalDataRequest));

      const createEditUrl = isEdit ? `/v1/service-provider-offers/update` : `/v1/service-provider-offers`;

      await ApiHelper.post(createEditUrl, {}, bookingDetailsFormData, {
        "Content-Type": "multipart/form-data"
      });

      onLoadOffers();
      isEdit ? onHandleFormMessage("ok", "EditOffer") : onHandleFormMessage("ok", "AddOffer");
    } catch (error) {
      isEdit ? onHandleFormMessage(null, "EditOffer") : onHandleFormMessage(null, "AddOffer");
    } finally {
      // after everything is loaded -> move to Hotel Offers list screen
      navigate(`/${providerRootUrl}/${pagesUrl.offers}`);
      // after everything is loaded -> set initial step to be active - Offer Details
      dispatch({
        type: offersActionTypes.SET_SELECTED_STEP,
        selectedStep: offersStep?.offerDetails
      });
      // after everything is loaded -> reset validatedSteps to default value
      dispatch({
        type: offersActionTypes.SET_VALIDATED_STEPS,
        validatedSteps: []
      });
      // turn off step loader
      dispatch({
        type: offersActionTypes.TOGGLE_STEP_LOADER,
        offerStepLoader: false
      });
    }
  },
  deleteOfferAction: (onHandleFormMessage, onLoadOffers, eventCode, navigate) => async dispatch => {
    try {
      await ApiHelper.delete(`/v1/service-provider-offers/${eventCode}`);

      onLoadOffers();
      onHandleFormMessage("ok", "DeleteOffer");
    } catch (error) {
      onHandleFormMessage(null, "DeleteOffer");
    } finally {
      // after everything is loaded -> move to Hotel Offers list screen
      navigate(`/${providerRootUrl}/${pagesUrl.offers}`);
    }
  },
  setErrorFieldCheckFixedTimeslotsAction:
    (day, timeslotIndex, action, disableAddButton, isAddDisabled) => (dispatch, getState) => {
      let dayMsg;
      switch (action) {
        // TIMESLOT TYPE: FIXED
        case "onChange":
          let statusOfAddBtnAfterChange = { validation: false };
          const timeslotsFromGivenDay = getState().offers.availabilityData.timeslotsFixedType[day];
          const errorFixedInputs = getState().offers.availabilityErrorFixed[day];

          // Check for duplicated slots and set error values to true or false
          const availabilityErrorFixedValidated = errorFixedInputs.map((m, indexOfErrorField) =>
            valueHasDuplicatesWithinValueKeys(
              timeslotsFromGivenDay.filter((f, index) => index !== indexOfErrorField),
              getState().offers.availabilityData?.timeslotsFixedType[day][indexOfErrorField]?.value
            )
              ? { error: true }
              : { error: false }
          );

          // Check if error fields have error with state true - check whether the timeslots/fields have errors
          if (checkErrorStatusByDay(availabilityErrorFixedValidated)) {
            // Set error message to corresponding day
            dayMsg = {
              error: true,
              availabilityErrorMsg: "You have defined duplicate timeslots."
            };

            dispatch({
              type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
              availabilityErrorFixed: {
                ...getState().offers.availabilityErrorFixed,
                [day]: availabilityErrorFixedValidated
              },
              availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
            });
            statusOfAddBtnAfterChange.validation = true;
          } else {
            dayMsg = {
              error: false,
              availabilityErrorMsg: ""
            };

            dispatch({
              type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
              availabilityErrorFixed: {
                ...getState().offers.availabilityErrorFixed,
                [day]: availabilityErrorFixedValidated
              },
              availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
            });
            statusOfAddBtnAfterChange.validation = false;
          }

          disableAddButton(statusOfAddBtnAfterChange.validation);
          return;
        case "add":
          let statusOfAddBtnAfterAdding = { validation: false };
          const fixedInputs = getState().offers.availabilityErrorFixed?.[day];
          const numberOfTimeslots = getState().offers.availabilityData.timeslotsFixedType[day]?.length;
          const timeslotsFromGivenDayAfterAdding = getState().offers.availabilityData.timeslotsFixedType[day];

          // Check if Add button is disabled
          if (!isAddDisabled) {
            // edd new error field for added timeslot
            fixedInputs.push({ error: false });

            // Check for duplicated slots after adding timeslot and set error values to true or false
            const availabilityErrorFixedValidatedAfterAdding = fixedInputs.map((m, indexOfErrorField) =>
              valueHasDuplicatesWithinValueKeys(
                timeslotsFromGivenDayAfterAdding.filter((f, index) => index !== indexOfErrorField),
                getState().offers.availabilityData?.timeslotsFixedType[day][indexOfErrorField]?.value
              )
                ? { error: true }
                : { error: false }
            );

            if (numberOfTimeslots > 1) {
              if (checkErrorStatusByDay(availabilityErrorFixedValidatedAfterAdding)) {
                // Set error message to corresponding day

                dayMsg = {
                  error: true,
                  availabilityErrorMsg: "You have defined duplicate timeslots."
                };

                dispatch({
                  type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
                  availabilityErrorFixed: {
                    ...getState().offers.availabilityErrorFixed,
                    [day]: availabilityErrorFixedValidatedAfterAdding
                  },
                  availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
                });
                statusOfAddBtnAfterAdding.validation = true;
              } else {
                dayMsg = {
                  error: false,
                  availabilityErrorMsg: ""
                };

                dispatch({
                  type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
                  availabilityErrorFixed: {
                    ...getState().offers.availabilityErrorFixed,
                    [day]: availabilityErrorFixedValidatedAfterAdding
                  },
                  availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
                });
                statusOfAddBtnAfterAdding.validation = false;
              }
            } else {
              dayMsg = {
                error: false,
                availabilityErrorMsg: ""
              };
              dispatch({
                type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
                availabilityErrorFixed: {
                  ...getState().offers.availabilityErrorFixed,
                  [day]: fixedInputs
                },
                availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
              });

              statusOfAddBtnAfterAdding.validation = false;
            }
            disableAddButton(statusOfAddBtnAfterAdding.validation);
          }

          return;

        case "remove":
          let statusOfAddBtnAfterRemoval = { validation: false };
          const timeslotsForGivenDay = getState().offers.availabilityData.timeslotsFixedType[day];
          // Remove error field for timeslot which is removed
          const fixedInputsArr = getState().offers.availabilityErrorFixed[day].filter(
            (m, index) => index !== timeslotIndex
          );

          // Check for duplicated slots after removing timeslot and set error values to true or false
          const availabilityErrorFixedValidatedAfterRemoving = fixedInputsArr.map((m, indexOfErrorField) =>
            valueHasDuplicatesWithinValueKeys(
              timeslotsForGivenDay.filter((f, index) => index !== indexOfErrorField),
              getState().offers.availabilityData?.timeslotsFixedType[day][indexOfErrorField]?.value
            )
              ? { error: true }
              : { error: false }
          );
          if (fixedInputsArr.length > 1) {
            if (checkErrorStatusByDay(availabilityErrorFixedValidatedAfterRemoving)) {
              // Set error message to corresponding day
              dayMsg = {
                error: true,
                availabilityErrorMsg: "You have defined duplicate timeslots."
              };

              dispatch({
                type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
                availabilityErrorFixed: {
                  ...getState().offers.availabilityErrorFixed,
                  [day]: availabilityErrorFixedValidatedAfterRemoving
                },
                availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
              });
              statusOfAddBtnAfterRemoval.validation = true;
            } else {
              dayMsg = {
                error: false,
                availabilityErrorMsg: ""
              };

              dispatch({
                type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
                availabilityErrorFixed: {
                  ...getState().offers.availabilityErrorFixed,
                  [day]: availabilityErrorFixedValidatedAfterRemoving
                },
                availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
              });
              statusOfAddBtnAfterRemoval.validation = false;
            }
          } else {
            dayMsg = {
              error: false,
              availabilityErrorMsg: ""
            };
            if (fixedInputsArr.length === 1) {
              fixedInputsArr[0].error = false;
            }
            dispatch({
              type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_FIX,
              availabilityErrorFixed: {
                ...getState().offers.availabilityErrorFixed,
                [day]: fixedInputsArr
              },
              availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg }
            });
            statusOfAddBtnAfterRemoval.validation = false;
          }

          disableAddButton(statusOfAddBtnAfterRemoval.validation);
          break;

        default:
          return;
      }
    },
  setErrorFieldCheckIntervalsTimeslotsAction:
    (day, timeslotIndex, action, disableAddButton, isAddDisabled) => (dispatch, getState) => {
      // TIMESLOT TYPE: INTERVALS
      switch (action) {
        case "onChange":
          let statusOfAddBtnAfterChange = { overlapping: false, validation: false };
          const intervalInputsArr = getState().offers.availabilityErrorIntervals[day].from;
          const timeslotIndexesWhichOverlapping = overlapCheck(
            getState().offers.availabilityData.timeslotsIntervalType[day]
          );

          if (timeslotIndexesWhichOverlapping.length > 0) {
            // Set error message to corresponding day
            const dayMsg = {
              error: true,
              availabilityErrorMsg: "You have defined overlapping timeslot blocks."
            };

            // Check for intervals validity and set error values to true or false and set validity Error Message
            const availabilityErrorFieldsValidated = intervalInputsArr.map((m, indexOfErrorField) =>
              !checkIntervalValidity(
                getState().offers.availabilityData.timeslotsIntervalType[day].from[indexOfErrorField].value,
                getState().offers.availabilityData.timeslotsIntervalType[day].to[indexOfErrorField].value
              )
                ? { error: true, errorValidationMsg: "This timeslot block is not valid." }
                : { error: false, errorValidationMsg: "" }
            );
            const mapFrom = availabilityErrorFieldsValidated.map((m, index) =>
              timeslotIndexesWhichOverlapping.includes(index) ? { ...m, error: true } : { ...m }
            );
            const mapTo = availabilityErrorFieldsValidated.map((m, index) =>
              timeslotIndexesWhichOverlapping.includes(index) ? { ...m, error: true } : { ...m }
            );

            statusOfAddBtnAfterChange.validation = checkErrorStatusByDay(availabilityErrorFieldsValidated);

            // Dispatch an action to set error status to intervals slots and to corresponding day
            dispatch({
              type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
              availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg },
              availabilityErrorIntervals: {
                ...getState().offers.availabilityErrorIntervals,
                [day]: {
                  from: mapFrom,
                  to: mapTo
                }
              }
            });
            statusOfAddBtnAfterChange.overlapping = true;
          } else {
            // Set empty error message
            const dayMsg = {
              error: false,
              availabilityErrorMsg: ""
            };

            const availabilityErrorFieldsValidated = intervalInputsArr.map((m, indexOfErrorField) =>
              !checkIntervalValidity(
                getState().offers.availabilityData.timeslotsIntervalType[day].from[indexOfErrorField].value,
                getState().offers.availabilityData.timeslotsIntervalType[day].to[indexOfErrorField].value
              )
                ? { error: true, errorValidationMsg: "This timeslot block is not valid." }
                : { error: false, errorValidationMsg: "" }
            );
            // Set all interval slots to false if there are no invalid ranges
            const mapFrom = availabilityErrorFieldsValidated.map(field => (field ? { ...field } : field));
            const mapTo = availabilityErrorFieldsValidated.map(field => (field ? { ...field } : field));

            statusOfAddBtnAfterChange.validation = checkErrorStatusByDay(availabilityErrorFieldsValidated);

            dispatch({
              type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
              availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg },
              availabilityErrorIntervals: {
                ...getState().offers.availabilityErrorIntervals,
                [day]: {
                  from: mapFrom,
                  to: mapTo
                }
              }
            });
            statusOfAddBtnAfterChange.overlapping = false;
          }
          if (statusOfAddBtnAfterChange.overlapping || statusOfAddBtnAfterChange.validation) {
            disableAddButton(true);
          } else {
            disableAddButton(false);
          }
          break;
        case "remove":
          let statusOfAddBtnAfterRemoval = { overlapping: false };

          const fromInputsAfterRemoval = getState().offers.availabilityErrorIntervals[day].from.filter(
            (m, index) => index !== timeslotIndex
          );
          const toInputsAfterRemoval = getState().offers.availabilityErrorIntervals[day].to.filter(
            (m, index) => index !== timeslotIndex
          );
          const timeslotsPerDay = getState().offers.availabilityData.timeslotsIntervalType[day];
          const timeslotIndexesWhichOverlappingAfterRemoval = overlapCheck(timeslotsPerDay);

          statusOfAddBtnAfterRemoval.validation = checkIntervalSlotsErrorByDay({
            from: fromInputsAfterRemoval,
            to: toInputsAfterRemoval
          });

          if (timeslotIndexesWhichOverlappingAfterRemoval.length > 0) {
            const mapFrom = fromInputsAfterRemoval.map((m, index) =>
              timeslotIndexesWhichOverlappingAfterRemoval.includes(index)
                ? { ...m, error: true }
                : { ...m, error: false }
            );
            const mapTo = toInputsAfterRemoval.map((m, index) =>
              timeslotIndexesWhichOverlappingAfterRemoval.includes(index)
                ? { ...m, error: true }
                : { ...m, error: false }
            );
            // Set error message to corresponding day
            const dayMsg = {
              error: true,
              availabilityErrorMsg: "You have defined overlapping timeslot blocks."
            };
            // Dispatch an action to set error status to intervals slots and to corresponding day
            dispatch({
              type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
              availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg },
              availabilityErrorIntervals: {
                ...getState().offers.availabilityErrorIntervals,

                [day]: {
                  from: mapFrom,
                  to: mapTo
                }
              }
            });
            statusOfAddBtnAfterRemoval.overlapping = true;
          } else {
            const mapFrom = fromInputsAfterRemoval.map((m, index) =>
              timeslotIndexesWhichOverlappingAfterRemoval.includes(index)
                ? { ...m, error: true }
                : { ...m, error: false }
            );
            const mapTo = toInputsAfterRemoval.map((m, index) =>
              timeslotIndexesWhichOverlappingAfterRemoval.includes(index)
                ? { ...m, error: true }
                : { ...m, error: false }
            );

            // Set empty error message
            const dayMsg = {
              error: false,
              availabilityErrorMsg: ""
            };
            dispatch({
              type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
              availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg },
              availabilityErrorIntervals: {
                ...getState().offers.availabilityErrorIntervals,
                [day]: {
                  from: mapFrom,
                  to: mapTo
                }
              }
            });
            statusOfAddBtnAfterRemoval.overlapping = false;
          }

          if (statusOfAddBtnAfterRemoval.overlapping || statusOfAddBtnAfterRemoval.validation) {
            disableAddButton(true);
          } else {
            disableAddButton(false);
          }
          break;

        case "add":
          let statusOfAddBtnAfterAdding = { overlapping: false, validation: false };
          const fromInput = getState().offers.availabilityErrorIntervals[day].from;
          const toInput = getState().offers.availabilityErrorIntervals[day].to;
          const numberOfTimeslots = getState().offers.availabilityData.timeslotsIntervalType[day].from.length;

          // If adding timeslot is not disable
          if (!isAddDisabled) {
            fromInput.push({ error: false });
            toInput.push({ error: false });

            if (numberOfTimeslots > 1) {
              const timeslotsPerDayAfterAdd = getState().offers.availabilityData.timeslotsIntervalType[day];
              const timeslotIndexesWhichOverlappingAfterAdd = overlapCheck(timeslotsPerDayAfterAdd);
              if (timeslotIndexesWhichOverlappingAfterAdd.length > 0) {
                if (!isAddDisabled) {
                  const mapFrom = fromInput.map((m, index) =>
                    timeslotIndexesWhichOverlappingAfterAdd.includes(index)
                      ? { ...m, error: true }
                      : { ...m, error: false }
                  );
                  const mapTo = toInput.map((m, index) =>
                    timeslotIndexesWhichOverlappingAfterAdd.includes(index)
                      ? { ...m, error: true }
                      : { ...m, error: false }
                  );

                  // Set error message to corresponding day
                  const dayMsg = {
                    error: true,
                    availabilityErrorMsg: "You have defined overlapping timeslot blocks."
                  };

                  // Dispatch an action to set error status to intervals slots and to corresponding day
                  dispatch({
                    type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
                    availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg },
                    availabilityErrorIntervals: {
                      ...getState().offers.availabilityErrorIntervals,

                      [day]: {
                        from: mapFrom,
                        to: mapTo
                      }
                    }
                  });
                }

                statusOfAddBtnAfterAdding.overlapping = true;
              } else {
                // Set error status after checking if is index included in Array of Invalid Indexes
                const mapFrom = fromInput.map((m, index) =>
                  timeslotIndexesWhichOverlappingAfterAdd.includes(index)
                    ? { ...m, error: true }
                    : { ...m, error: false }
                );
                const mapTo = toInput.map((m, index) =>
                  timeslotIndexesWhichOverlappingAfterAdd.includes(index)
                    ? { ...m, error: true }
                    : { ...m, error: false }
                );

                // Set empty error message
                const dayMsg = {
                  error: false,
                  availabilityErrorMsg: ""
                };

                dispatch({
                  type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
                  availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg },
                  availabilityErrorIntervals: {
                    ...getState().offers.availabilityErrorIntervals,
                    [day]: {
                      from: mapFrom,
                      to: mapTo
                    }
                  }
                });

                statusOfAddBtnAfterAdding.overlapping = false;
              }
            } else {
              const dayMsg = {
                error: false,
                availabilityErrorMsg: ""
              };

              dispatch({
                type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
                availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg, [day]: dayMsg },

                availabilityErrorIntervals: {
                  ...getState().offers.availabilityErrorIntervals,

                  [day]: {
                    from: fromInput,
                    to: toInput
                  }
                }
              });

              statusOfAddBtnAfterAdding.overlapping = false;
            }

            const addedSlotIndex = getState().offers.availabilityData.timeslotsIntervalType[day].from.length - 1;

            if (
              !checkIntervalValidity(
                getState().offers.availabilityData.timeslotsIntervalType[day].from[addedSlotIndex].value,
                getState().offers.availabilityData.timeslotsIntervalType[day].to[addedSlotIndex].value
              )
            ) {
              // Set error true to current interval ("From" input) timeslot in timeslots range that is not valid
              const mapFrom = getState().offers.availabilityErrorIntervals[day].from.map((m, index) =>
                index === addedSlotIndex ? { ...m, errorValidationMsg: "This timeslot block is not valid." } : m
              );
              // Set error true to current interval ("To" input) timeslot in timeslots range that is not valid
              const mapTo = getState().offers.availabilityErrorIntervals[day].to.map((m, index) =>
                index === addedSlotIndex ? { ...m, errorValidationMsg: "This timeslot block is not valid." } : m
              );

              // Dispatch an action to set error status to intervals fields and to corresponding day
              dispatch({
                type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG_INT,
                availabilityErrorMsg: { ...getState().offers.availabilityErrorMsg },
                availabilityErrorIntervals: {
                  ...getState().offers.availabilityErrorIntervals,
                  [day]: {
                    from: mapFrom,
                    to: mapTo
                  }
                }
              });

              statusOfAddBtnAfterAdding.validation = true;
            } else {
              statusOfAddBtnAfterAdding.validation = false;
            }

            if (statusOfAddBtnAfterAdding.overlapping || statusOfAddBtnAfterAdding.validation) {
              disableAddButton(true);
            } else {
              disableAddButton(false);
            }
          }

          break;
        default:
          return null;
      }
    },

  setSelectedStepAction: (step, buttonTriggered) => (dispatch, getState) => {
    const stepsToValidate = getStepsToValidate(step);
    const currentStep = getState().offers.selectedStep;
    switch (buttonTriggered) {
      case "next":
        const errorCheckStep = dispatch(
          errorFieldActionCreators.errorFieldCheckInStepAction(currentStep, getState().offers[currentStep + "Data"])
        );
        if (errorCheckStep) {
          let validatedSteps = getState().offers.validatedSteps;

          if (!validatedSteps.includes(currentStep)) {
            validatedSteps.push(currentStep);
            dispatch({
              type: offersActionTypes.SET_VALIDATED_STEPS,
              validatedSteps: validatedSteps
            });
          }

          dispatch({
            type: offersActionTypes.SET_SELECTED_STEP,
            selectedStep: step
          });
        }
        break;
      case "previous":
        const errorCheckCurrentStep = dispatch(
          errorFieldActionCreators.errorFieldCheckInStepAction(currentStep, getState().offers[currentStep + "Data"])
        );

        if (errorCheckCurrentStep) {
          let validatedSteps = getState().offers.validatedSteps;

          if (!validatedSteps.includes(currentStep)) {
            validatedSteps.push(currentStep);
            dispatch({
              type: offersActionTypes.SET_VALIDATED_STEPS,
              validatedSteps: validatedSteps
            });
          }

          dispatch({
            type: offersActionTypes.SET_SELECTED_STEP,
            selectedStep: step
          });
        }
        break;
      case "step":
        let validationArr = [];
        let validatedSteps = getState().offers.validatedSteps;
        for (let i = 0; i < stepsToValidate?.length; i++) {
          validationArr.push(validatedSteps.includes(stepsToValidate[i]));
        }

        if (currentStep === "fulfilment" || validationStepMatchCheck(validationArr)) {
          const errorCheckCurrent = dispatch(
            errorFieldActionCreators.errorFieldCheckInStepAction(currentStep, getState().offers[currentStep + "Data"])
          );

          if (errorCheckCurrent) {
            if (!validatedSteps.includes(currentStep)) {
              validatedSteps.push(currentStep);
              dispatch({
                type: offersActionTypes.SET_VALIDATED_STEPS,
                validatedSteps: validatedSteps
              });
            }

            if (currentStep === "fulfilment" || validatedSteps.includes(step)) {
              dispatch({
                type: offersActionTypes.SET_SELECTED_STEP,
                selectedStep: step
              });
            }
          }
        }
        break;
      default:
        return null;
    }
  },
  setOfferForEditAction:
    ({ offerDetailsData, pricingData, availabilityData, photosData, fulfilmentData }) =>
    (dispatch, getState) => {
      dispatch({
        type: offersActionTypes.SET_VALIDATED_STEPS,
        validatedSteps: steps
      });

      const availabilityErrorFixed = getState().offers.availabilityErrorFixed;
      const availabilityErrorIntervals = getState().offers.availabilityErrorIntervals;

      days.forEach(day => {
        for (let i = 0; i < availabilityData.timeslotsFixedType[day].length; i++) {
          availabilityErrorFixed[day][i] = { error: false };
        }
      });

      days.forEach(day => {
        for (let i = 0; i < availabilityData.timeslotsIntervalType[day]["from"].length; i++) {
          availabilityErrorIntervals[day]["from"][i] = { error: false };
          availabilityErrorIntervals[day]["to"][i] = { error: false };
        }
      });

      dispatch({
        type: offersActionTypes.UPDATE_TIMESLOTS_ERRORS,
        availabilityErrorFixed: availabilityErrorFixed,
        availabilityErrorIntervals: availabilityErrorIntervals
      });

      dispatch({
        type: offersActionTypes.SET_OFFER_FOR_EDIT,
        offerDetailsData,
        pricingData,
        availabilityData,
        photosData,
        fulfilmentData
      });
    },
  resetOfferStepDataAction: () => dispatch => {
    dispatch({
      type: offersActionTypes.RESET_OFFER_STEP_DATA
    });
  },
  resetAvailabilityTimeslotsDataAction: () => dispatch => {
    dispatch({
      type: offersActionTypes.SET_TIMESLOTS_ERROR_MSG,
      availabilityErrorMsg: null
    });
    dispatch({
      type: offersActionTypes.RESET_AVAILABILITY_TIMESLOTS_ERRORS
    });
    dispatch({
      type: offersActionTypes.RESET_AVAILABILITY_TIMESLOTS_DATA
    });
  },
  removePhotoIdAction: photoId => dispatch => {
    dispatch({
      type: offersActionTypes.PHOTO_IDS_REMOVE,
      photoIdsForRemove: photoId
    });
  },
  setIfFormChangedAction: state => dispatch => {
    dispatch({
      type: offersActionTypes.SET_IS_FORM_CHANGED_STATE,
      isFormChanged: state
    });
  },
  updateStepAction: (step, data) => (dispatch, getState) => {
    const isFormChangedState = getState().offers.isFormChanged;
    if (!isFormChangedState) {
      dispatch({
        type: offersActionTypes.SET_IS_FORM_CHANGED_STATE,
        isFormChanged: true
      });
    }

    switch (step) {
      case offersStep.offerDetails:
        dispatch({
          type: offersActionTypes.UPDATE_DETAILS_STEP,
          offerDetailsData: data
        });
        break;
      case offersStep.pricing:
        dispatch({
          type: offersActionTypes.UPDATE_PRICING_STEP,
          pricingData: data
        });
        break;
      case offersStep.photos:
        dispatch({
          type: offersActionTypes.UPDATE_PHOTOS_STEP,
          photosData: data
        });
        break;
      case offersStep.fulfilment:
        dispatch({
          type: offersActionTypes.UPDATE_FULFILMENT_STEP,
          fulfilmentData: data
        });
        break;
      case offersStep.availability:
        dispatch({
          type: offersActionTypes.UPDATE_AVAILABILITY_STEP,
          availabilityData: data
        });
        break;
      default:
        return null;
    }
  }
};
